var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"proposal-content"},[_c('div',{staticClass:"proposal-main"},[_c('user-card',{attrs:{"obj":{ avatar: _vm.avatar, name: _vm.userName, job: _vm.jobName }},scopedSlots:_vm._u([{key:"tip",fn:function(){return [_c('span',{staticClass:"instructions",on:{"click":function($event){_vm.showDialog = true}}},[_vm._v("填写说明")])]},proxy:true}])}),(_vm.formType.length > 0)?_c('el-form',{ref:"ruleForm",staticClass:"reset-form",attrs:{"model":_vm.form,"show-message":true,"inline-message":"","label-width":"80px","label-position":"top"}},[_c('div',{staticClass:"form-list"},[(_vm.formType[0].isEnabled)?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":_vm.formType[0].showName || '实施单位',"rules":{
              required: _vm.formType[0].isMust,
              message: ((_vm.formType[0].showName) + "不能为空"),
              trigger: 'change'
            },"prop":"implementOrgId"}},[_c('el-select',{staticClass:"org-sel",attrs:{"filterable":"","remote":"","placeholder":"请选择","remote-method":_vm.remoteMethod,"clearable":""},on:{"change":_vm.handleOrgName},model:{value:(_vm.form.implementOrgId),callback:function ($$v) {_vm.$set(_vm.form, "implementOrgId", $$v)},expression:"form.implementOrgId"}},_vm._l((_vm.orgList),function(item){return _c('el-option',{key:item.orgId,attrs:{"label":item.orgChain,"value":item.orgId}})}),1)],1)],1):_vm._e()]),_c('div',{staticClass:"form-list"},[(_vm.formType[1].isEnabled)?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"prop":"contributeCategoryName","rules":{
              required: _vm.formType[1].isMust,
              message: ((_vm.formType[1].showName) + "不能为空"),
              trigger: 'change'
            },"label":_vm.formType[1].showName || '提案类别'}},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":_vm.handlCategoryCode},model:{value:(_vm.form.contributeCategoryName),callback:function ($$v) {_vm.$set(_vm.form, "contributeCategoryName", $$v)},expression:"form.contributeCategoryName"}},_vm._l((_vm.category),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.proposalDesc,"value":item.proposalDesc}})}),1)],1)],1):_vm._e(),(_vm.formType[2].isEnabled)?_c('div',{staticClass:"item date"},[_c('el-form-item',{attrs:{"prop":"proposalDate","rules":{
              required: _vm.formType[2].isMust,
              message: ((_vm.formType[2].showName) + "不能为空"),
              trigger: 'change'
            },"label":_vm.formType[2].showName || '提案日期'}},[_c('el-date-picker',{attrs:{"type":"date","placeholder":"请选择","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions},model:{value:(_vm.form.proposalDate),callback:function ($$v) {_vm.$set(_vm.form, "proposalDate", $$v)},expression:"form.proposalDate"}})],1)],1):_vm._e()]),_c('div',{staticClass:"form-list"},[(_vm.formType[3].isEnabled)?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"rules":[
              {
                required: _vm.formType[3].isMust,
                message: ((_vm.formType[3].showName) + "不能为空"),
                trigger: 'blur',
                validator: _vm.formType[3].isMust ? _vm.$checkTrim : ''
              },
              { min: 1, max: 50, message: ((_vm.formType[3].showName) + "限50字之内"), trigger: 'blur' }
            ],"label":_vm.formType[3].showName || '标题',"prop":"title"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 3 },"placeholder":"请输入","maxlength":"50","show-word-limit":"","resize":"none"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1)],1):_vm._e(),(_vm.formType[4].isEnabled)?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":_vm.formType[4].showName || '要点',"rules":[
              {
                required: _vm.formType[4].isMust,
                message: ((_vm.formType[4].showName) + "不能为空"),
                trigger: 'blur',
                validator: _vm.formType[4].isMust ? _vm.$checkTrim : ''
              },
              {
                min: 1,
                max: 800,
                message: ((_vm.formType[4].showName) + "限800字之内"),
                trigger: 'blur'
              }
            ],"prop":"gist"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 10 },"maxlength":"800","show-word-limit":"","placeholder":"请输入","resize":"none"},model:{value:(_vm.form.gist),callback:function ($$v) {_vm.$set(_vm.form, "gist", $$v)},expression:"form.gist"}})],1)],1):_vm._e(),(_vm.formType[5].isEnabled)?_c('div',{staticClass:"item"},[_c('el-form-item',{attrs:{"label":_vm.formType[5].showName || '内容',"rules":[
              {
                required: _vm.formType[5].isMust,
                message: ((_vm.formType[5].showName) + "不能为空"),
                trigger: 'blur',
                validator: _vm.formType[5].isMust ? _vm.$checkTrim : ''
              },
              {
                min: 1,
                max: 3000,
                message: ((_vm.formType[5].showName) + "限3000字之内"),
                trigger: 'blur'
              }
            ],"prop":"contributeContent"}},[_c('el-input',{directives:[{name:"filteremoji",rawName:"v-filteremoji"}],attrs:{"type":"textarea","autosize":{ minRows: 1, maxRows: 10 },"placeholder":"请输入","maxlength":"3000","show-word-limit":"","resize":"none"},model:{value:(_vm.form.contributeContent),callback:function ($$v) {_vm.$set(_vm.form, "contributeContent", $$v)},expression:"form.contributeContent"}})],1)],1):_vm._e()]),(_vm.formType[6].isEnabled)?_c('div',{staticClass:"form-list"},[_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"file-box"},[_c('div',{staticClass:"file-title",class:_vm.formType[6].isMust ? 'tip' : ''},[_vm._v(" "+_vm._s(_vm.formType[6].showName || '上传文件')+" ")]),_vm._l((_vm.filePathList),function(file,index){return _c('tojoy-file',{key:index,staticClass:"file-item",attrs:{"isShow":true,"index":index,"data":{
                type: file.fileSuffix || file.fileType,
                fileName: file.fileName || '暂无',
                url: file.filePath
              }},on:{"remove":_vm.handleRemove}})})],2)]),_c('img-upload',{staticClass:"upload-box",attrs:{"num":_vm.filePathList.length,"uploadType":"contribute-annex","disabled":true},on:{"handleSuccess":_vm.handleSuccess}})],1):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"result-bottom"},[(_vm.type === 'add')?_c('el-button',{staticClass:"white",on:{"click":_vm.handleSaveDrafts}},[_vm._v("保存草稿")]):_vm._e(),_c('el-button',{staticClass:"blue",attrs:{"type":"primary"},on:{"click":_vm.handleNode}},[_vm._v("提交")])],1),_c('approvalExplanation',{attrs:{"showDialog":_vm.showDialog,"type":1,"modelType":"team"}}),_c('approvalNode',{attrs:{"display":_vm.showNodeDialog,"id":_vm.orgId,"innovateLevel":_vm.contributeCategory,"innovateLevelName":_vm.form.contributeCategoryName,"modelType":"team"},on:{"before-close":_vm.handleClose,"handleConfirm":_vm.handleConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }