<template>
  <div class="proposal-content" v-loading="loading">
    <div class="proposal-main">
      <user-card :obj="{ avatar: avatar, name: userName, job: jobName }">
        <template #tip>
          <span class="instructions" @click="showDialog = true">填写说明</span>
        </template>
      </user-card>
      <el-form
        :model="form"
        :show-message="true"
        inline-message
        ref="ruleForm"
        label-width="80px"
        label-position="top"
        v-if="formType.length > 0"
        class="reset-form"
      >
        <div class="form-list">
          <div class="item" v-if="formType[0].isEnabled">
            <el-form-item
              :label="formType[0].showName || '实施单位'"
              :rules="{
                required: formType[0].isMust,
                message: `${formType[0].showName}不能为空`,
                trigger: 'change'
              }"
              prop="implementOrgId"
            >
              <el-select
                v-model="form.implementOrgId"
                filterable
                remote
                placeholder="请选择"
                :remote-method="remoteMethod"
                clearable
                class="org-sel"
                @change="handleOrgName"
              >
                <el-option
                  v-for="item in orgList"
                  :key="item.orgId"
                  :label="item.orgChain"
                  :value="item.orgId"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
        </div>
        <div class="form-list">
          <div class="item" v-if="formType[1].isEnabled">
            <el-form-item
              prop="contributeCategoryName"
              :rules="{
                required: formType[1].isMust,
                message: `${formType[1].showName}不能为空`,
                trigger: 'change'
              }"
              :label="formType[1].showName || '提案类别'"
            >
              <el-select
                v-model="form.contributeCategoryName"
                placeholder="请选择"
                @change="handlCategoryCode"
              >
                <el-option
                  v-for="item in category"
                  :key="item.id"
                  :label="item.proposalDesc"
                  :value="item.proposalDesc"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="item date" v-if="formType[2].isEnabled">
            <el-form-item
              prop="proposalDate"
              :rules="{
                required: formType[2].isMust,
                message: `${formType[2].showName}不能为空`,
                trigger: 'change'
              }"
              :label="formType[2].showName || '提案日期'"
            >
              <el-date-picker
                type="date"
                placeholder="请选择"
                v-model="form.proposalDate"
                value-format="yyyy-MM-dd"
                :picker-options="pickerOptions"
              ></el-date-picker>
            </el-form-item>
          </div>
        </div>
        <div class="form-list">
          <div class="item" v-if="formType[3].isEnabled">
            <el-form-item
              :rules="[
                {
                  required: formType[3].isMust,
                  message: `${formType[3].showName}不能为空`,
                  trigger: 'blur',
                  validator: formType[3].isMust ? $checkTrim : ''
                },
                { min: 1, max: 50, message: `${formType[3].showName}限50字之内`, trigger: 'blur' }
              ]"
              :label="formType[3].showName || '标题'"
              prop="title"
            >
              <el-input
                v-filteremoji
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 3 }"
                v-model="form.title"
                placeholder="请输入"
                maxlength="50"
                show-word-limit
                resize="none"
              ></el-input>
            </el-form-item>
          </div>
          <div class="item" v-if="formType[4].isEnabled">
            <el-form-item
              :label="formType[4].showName || '要点'"
              :rules="[
                {
                  required: formType[4].isMust,
                  message: `${formType[4].showName}不能为空`,
                  trigger: 'blur',
                  validator: formType[4].isMust ? $checkTrim : ''
                },
                {
                  min: 1,
                  max: 800,
                  message: `${formType[4].showName}限800字之内`,
                  trigger: 'blur'
                }
              ]"
              prop="gist"
            >
              <el-input
                v-filteremoji
                type="textarea"
                :autosize="{ minRows: 1, maxRows: 10 }"
                maxlength="800"
                show-word-limit
                v-model="form.gist"
                placeholder="请输入"
                resize="none"
              ></el-input>
            </el-form-item>
          </div>
          <div class="item" v-if="formType[5].isEnabled">
            <el-form-item
              :label="formType[5].showName || '内容'"
              :rules="[
                {
                  required: formType[5].isMust,
                  message: `${formType[5].showName}不能为空`,
                  trigger: 'blur',
                  validator: formType[5].isMust ? $checkTrim : ''
                },
                {
                  min: 1,
                  max: 3000,
                  message: `${formType[5].showName}限3000字之内`,
                  trigger: 'blur'
                }
              ]"
              prop="contributeContent"
            >
              <el-input
                v-filteremoji
                type="textarea"
                v-model="form.contributeContent"
                :autosize="{ minRows: 1, maxRows: 10 }"
                placeholder="请输入"
                maxlength="3000"
                show-word-limit
                resize="none"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="form-list" v-if="formType[6].isEnabled">
          <div class="item-box">
            <div class="file-box">
              <div class="file-title" :class="formType[6].isMust ? 'tip' : ''">
                {{ formType[6].showName || '上传文件' }}
              </div>
              <tojoy-file
                class="file-item"
                :isShow="true"
                v-for="(file, index) in filePathList"
                :index="index"
                :key="index"
                @remove="handleRemove"
                :data="{
                  type: file.fileSuffix || file.fileType,
                  fileName: file.fileName || '暂无',
                  url: file.filePath
                }"
              />
            </div>
          </div>
          <img-upload
            :num="filePathList.length"
            uploadType="contribute-annex"
            :disabled="true"
            @handleSuccess="handleSuccess"
            class="upload-box"
          ></img-upload>
        </div>
      </el-form>
    </div>
    <div class="result-bottom">
      <el-button @click="handleSaveDrafts" class="white" v-if="type === 'add'">保存草稿</el-button>
      <el-button type="primary" class="blue" @click="handleNode">提交</el-button>
    </div>
    <approvalExplanation :showDialog="showDialog" :type="1" modelType="team" />
    <approvalNode
      :display="showNodeDialog"
      @before-close="handleClose"
      :id="orgId"
      :innovateLevel="contributeCategory"
      :innovateLevelName="form.contributeCategoryName"
      modelType="team"
      @handleConfirm="handleConfirm"
    />
  </div>
</template>

<script>
import userCard from '@/components/initiate/user-card'
import approvalExplanation from '@/components/initiate/approval-explanation'
import approvalNode from '@/components/initiate/approval-node'
import {
  postInnovationEdit,
  postInnovationAdd,
  getInnovationCategory,
  getDataDraft,
  getOrgList,
  postDataDraftSave,
  getInnovationDetail,
  getFromList
} from '@/api/api_team'
import TojoyFile from '@/components/common/file'
import ImgUpload from '@/components/common/file/upload.vue'
import { mapState } from 'vuex'
export default {
  name: 'initiate-proposal',
  components: {
    userCard,
    approvalExplanation,
    approvalNode,
    TojoyFile,
    ImgUpload
  },
  props: {
    type: {
      type: String,
      default: 'add'
    },
    id: {
      type: String,
      default: ''
    },
    moduleName: {
      type: String,
      default: 'all'
    }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      modelCode: 'team',
      formObj: {},
      processObj: {},
      loading: false,
      formIds: {},
      formType: [],
      orgId: 0,
      orgName: '',
      orgList: [],
      category: [
        { id: 56, proposalDesc: '应用2' },
        { id: 57, proposalDesc: '应用1' }
      ],
      showNodeDialog: false,
      showDialog: false,
      processExampleDtoList: [], //流程选择
      filePathList: [], //附件列表
      contributeCategory: null, //提案类别
      form: {
        implementOrgId: null, //实施单位
        contributeCategoryName: '', //提案类别
        proposalDate: '', //提案日期
        title: '', //标题
        gist: '', //要点
        contributeContent: '' //内容
      },
      rules: {}
    }
  },
  computed: {
    ...mapState({
      avatar: state => state.userInfo.avatar,
      userName: state => state.userInfo.userName,
      jobName: state =>
        state.userInfo.jobList && state.userInfo.jobList.length > 0
          ? state.userInfo.jobList[0].jobName
          : ''
    })
  },
  methods: {
    /**
     * 动态表单字段
     */
    handleFormList() {
      getFromList()
        .then(res => {
          if (res.data) {
            let keys = Object.keys(this.form)
            res.data.forEach((el, index) => {
              this.$set(this.formType, index, res.data[index])
              if (index === res.data.length - 1) {
                if (el.isEnabled) {
                  this.$set(this.formIds, 'filePathListFormId', res.data[index].formId)
                } else {
                  this.$set(this.formIds, 'filePathListFormId', null)
                }
              } else {
                if (keys[index] === 'contributeCategoryName') {
                  if (el.isEnabled) {
                    this.$set(this.formIds, keys[index].split('N')[0] + 'FormId', el.formId)
                  } else {
                    this.$set(this.formIds, keys[index].split('N')[0] + 'FormId', null)
                  }
                } else {
                  if (el.isEnabled) {
                    this.$set(this.formIds, keys[index] + 'FormId', res.data[index].formId)
                  } else {
                    this.$set(this.formIds, keys[index] + 'FormId', null)
                  }
                }
              }
            })
          } else {
            this.formType = []
          }
        })
        .catch(err => {})
    },
    /**
     * 提案详情
     */
    handleDetail() {
      let params = {
        contributeCode: this.id
      }
      getInnovationDetail(params)
        .then(res => {
          if (res.data) {
            let {
              implementOrgId, //实施单位
              implementOrgName,
              contributeCategory, //提案类别
              contributeCategoryName,
              proposalDate, //提案日期
              title, //标题
              gist, //要点
              contributeContent, //内容
              contributeAnnexList
            } = res.data
            this.formObj = res.data
            this.orgName = implementOrgName || ''
            this.form.implementOrgId = implementOrgId || null
            this.contributeCategory = contributeCategory || null
            this.form.contributeCategoryName = contributeCategoryName || ''
            this.form.proposalDate = proposalDate || ''
            this.form.title = title || ''
            this.form.gist = gist || ''
            this.form.contributeContent = contributeContent || ''
            this.filePathList = contributeAnnexList || []
            this.remoteMethod(this.orgName)
            let formkeys = Object.keys(this.form)
            formkeys.push('contributeAnnexList')
            let data = res.data
            for (let key in data) {
              formkeys.forEach((el, index) => {
                if (el === key) {
                  if (el === 'contributeCategoryName') {
                    el = el.split('N')[0]
                  }
                  let obj = {
                    isEnabled: data[`${el}FormCode`],
                    isMust: data[`${el}FormMust`],
                    showName: data[`${el}FormName`]
                  }
                  if (el === 'contributeAnnexList') {
                    this.$set(this.formIds, 'filePathListFormId', data[`${el}FormCode`])
                  } else {
                    this.$set(this.formIds, `${el}FormId`, data[`${el}FormCode`])
                  }
                  this.$set(this.formType, index, obj)
                }
              })
            }
          } else {
            this.initiateObj = {}
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**
     * 上传文件成功回调
     */
    handleSuccess(res, file) {
      let fileSuffix = this.$getFileSuffix(file.name)
      let fileName = this.$splitFileName(file.name)
      let obj = {
        fileSuffix: fileSuffix,
        fileName: fileName,
        filePath: res.list[0]
      }
      this.filePathList.push(obj)
    },
    /**
     * 删除文件
     */
    handleRemove(index, data) {
      this.filePathList.splice(index, 1)
    },
    handleNode() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          if (
            this.formType[6].isEnabled &&
            this.formType[6].isMust &&
            this.filePathList.length < 1
          ) {
            this.$msgError('上传文件不能为空！')
            return false
          }
          this.showNodeDialog = true
          this.orgId = this.form.implementOrgId
        } else {
          return false
        }
      })
    },
    /**
     * 实施单位搜索
     */
    remoteMethod(val) {
      if (val === '') {
        this.orgList = []
      } else {
        getOrgList({ orgName: val })
          .then(res => {
            let { orgList } = res.data
            this.orgList = orgList
          })
          .catch(err => {
            console.log(err)
          })
      }
    },
    handleOrgName(val) {
      if (val) {
        this.orgName = this.orgList.find(el => el.orgId === val)?.orgName
      } else {
        this.orgName = ''
      }
    },
    /**
     * 关闭审批节点弹框
     */
    handleClose(val) {
      this.showNodeDialog = val
    },
    /**
     * 保存草稿箱
     */
    handleSaveDrafts() {
      let {
        implementOrgId,
        contributeCategoryName,
        proposalDate,
        title,
        gist,
        contributeContent
      } = this.form
      let params = {
        implementOrgId,
        contributeCategoryName,
        contributeCategory: this.contributeCategory,
        proposalDate,
        implementOrgName: this.orgName,
        title,
        gist,
        contributeContent,
        filePathList: this.filePathList,
        ...this.formIds
      }
      postDataDraftSave(this.$filterParamsTrim(params))
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess('操作成功')
            this.getDataDraft()
          }
        })
        .catch(err => {})
    },
    /**
     * 草稿箱回显
     */
    getDataDraft() {
      getDataDraft()
        .then(res => {
          if (!res.data) return false
          let {
            implementOrgId, //实施单位
            implementOrgName,
            contributeCategory, //提案类别
            contributeCategoryName,
            proposalDate, //提案日期
            title, //标题
            gist, //要点
            contributeContent, //内容
            filePathList
          } = res.data
          let arr = implementOrgName ? implementOrgName.split('/') : []
          this.orgName = (arr.length > 0 && arr[arr.length - 1]) || ''
          this.form.implementOrgId = implementOrgId || null
          this.form.contributeCategoryName = contributeCategoryName || ''
          this.contributeCategory = contributeCategory || null
          this.form.proposalDate = proposalDate || ''
          this.form.title = title || ''
          this.form.gist = gist || ''
          this.form.contributeContent = contributeContent || ''
          this.filePathList = filePathList || []
          this.remoteMethod(this.orgName)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async handleAdd(params) {
      try {
        if (this.type === 'add') {
          return await postInnovationAdd({ ...params, ...this.formIds })
        } else {
          return await postInnovationEdit({ ...params, contributeCode: this.id })
        }
      } catch (err) {
        return err
      }
    },
    /**
     * 提交
     */
    handleConfirm(processExampleDtoList, processTemplateId, processTemplateName) {
      let {
        implementOrgId,
        contributeCategoryName,
        proposalDate,
        title,
        gist,
        contributeContent
      } = this.form
      let params = {
        implementOrgId,
        implementOrgName: this.orgName,
        contributeCategoryName,
        contributeCategory: this.contributeCategory,
        proposalDate,
        title,
        gist,
        contributeContent,
        filePathList: this.filePathList,
        processExampleDtoList,
        processTemplateId,
        processTemplateName
      }
      this.loading = true
      this.handleAdd(this.$filterParamsTrim(params))
        .then(res => {
          if (res.code === '000000') {
            this.$msgSuccess('操作成功')
            this.showNodeDialog = false
            this.$router.push({
              name: this.type === 'add' ? 'team-channel' : 'team-initiate',
              query: {
                moduleName: this.type === 'edit' ? this.moduleName : null
              }
            })
          }
        })
        .catch(err => {
          console.log('err', err)
          this.$msgError('服务异常')
        })
        .finally(() => {
          this.loading = false
        })
    },
    /**
     * 提案类别
     */
    getCategory() {
      getInnovationCategory()
        .then(res => {
          this.category = res.data ? res.data : []
        })
        .catch()
    },
    handlCategoryCode(val) {
      this.contributeCategory = this.category.find(el => el.proposalDesc === val).id
    }
  },
  created() {
    this.getCategory()
    if (this.type === 'add') {
      //新增-草稿箱
      this.handleFormList()
      this.getDataDraft()
    } else {
      //编辑-详情
      this.handleDetail()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/addProposal.scss';
</style>
