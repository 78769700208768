<template>
  <div>
    <el-upload
      class="img-uploader"
      action=""
      v-loading="loading"
      :http-request="handleUpload"
      :show-file-list="false"
      :before-upload="beforeUpload"
      :disabled="num > 9"
    >
      <i class="el-icon-circle-plus-outline file-icon" @click="handleRangeTip"
        ><span class="text">上传文件</span></i
      >
    </el-upload>
  </div>
</template>
<script>
import { upload } from '@/api/api_public'

export default {
  name: 'ImgUpload',
  data() {
    return {
      loading: false,
      type:
        'image/*,application/vnd.ms-powerpoint, application/docx,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,.txt'
    }
  },
  props: {
    num: {
      type: Number,
      default: 0
    },
    //上传相关参数
    uploadOptions: {
      type: Object,
      required: false,
      default: () => {
        return {
          size: 10,
          type: [
            'jpeg',
            'png',
            'jpg',
            'gif',
            'txt',
            'text',
            'docx',
            'doc',
            'xls',
            'xlsx',
            'ppt',
            'pptx',
            'pdf'
          ]
        }
      }
    },
    //上传文件对应模块
    uploadType: {
      type: String,
      default: ''
    }
  },
  methods: {
    async uploadImg(file) {
      try {
        const formdata = new FormData()
        formdata.append('files', file)
        formdata.append('business', this.uploadType)
        return await upload(formdata)
      } catch (err) {
        return err
      }
    },
    async handleUpload(info) {
      this.loading = true
      this.uploadImg(info.file)
        .then(res => {
          if (res && res.code === '000000') {
            this.loading = false
            this.$msgSuccess('上传成功')
            this.$emit('handleSuccess', res.data, info.file)
          } else {
            this.loading = false
            res && this.$msgError.error(res.msg)
          }
        })
        .catch(e => {
          console.log('打印异常', e)
        })
    },
    handleRangeTip() {
      if (this.num > 9) {
        this.$warning('最多可上传10个文件')
        return false
      } else {
        return true
      }
    },
    beforeUpload(file) {
      /**
       * 上传前的文件校验暂时没处理
       */
      return this.uploadImageRules(file, this.uploadOptions)
    },
    //上传图片格式大小限制
    async uploadImageRules(file, options) {
      const { size, type } = options
      let contrast = file.size / 1024 / 1024 <= size
      let fileSuffix = this.$getFileSuffix(file.name)
      let isPass = type.includes(fileSuffix)

      if (!isPass) {
        this.$msgError('文件格式只支持PNG/JPEG/JPG/GIF/WORD/EXCEL/PDF/TXT')
        return Promise.reject(isPass)
      }
      if (!contrast) {
        this.$msgError(`文件大小不能超过 ${size}MB!`)
        return Promise.reject(contrast)
      }

      return Promise.resolve(true)
    },
    //上传图片宽高限制
    verifyImagePixel(file, options) {
      const { width, height } = options
      if (!width || !height) {
        return Promise.resolve(true)
      }
      return new Promise((resolve, reject) => {
        let reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function(e) {
          let image = new Image()
          image.src = e.target.result
          image.onload = function() {
            let w = this.width
            let h = this.height
            if (w !== width || h !== height) {
              resolve(false)
            } else {
              resolve(true)
            }
          }
          image.error = function(err) {
            reject(err)
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.img-uploader {
  padding-right: 15px;
  padding-bottom: 15px;
  .file-icon {
    font-size: 15px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    line-height: 15px;
    .text {
      margin-left: 8px;
    }
  }
  /deep/ .el-upload {
    width: 100%;
    height: 64px;
    border: 1px dashed $grey600;
    color: $darkgrey;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      border-color: $blue;
      color: $blue;
    }
  }
}
</style>
